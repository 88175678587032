
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      username: "",
      error: "",
      skipping: false,
    };
  },
  created() {
    if (this.$route.params.username) {
      console.log(this.$route.params.username);
      this.skipping = true;
      this.username = this.$route.params.username;
      this.checkUsername();
    }
  },
  methods: {
    checkUsername() {
      this.error = "";
      fetch(
        process.env.VUE_APP_API_PATH + "profiles/" + this.username + "/preview",
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (!response.ok || response.status != 200) {
            throw response;
          }
          return await response.json(); //we only get here if there is no error
        })
        .then(
          async (text) => {
            await this.$router.push({
              name: "preview",
              params: { data: JSON.stringify(text.data) },
            });
          },
          (response) => {
            this.skipping = false;
            this.error =
              response.status === 404
                ? "We could not find a profile for given username."
                : "This date-me link is not active right now.";
          }
        );
    },
    isUsernameOkay(username: string) {
      const split = username.split("+");
      if (split.length != 2) {
        return false;
      }
      return split[1].length === 5;
    },
  },
})
export default class Home extends Vue {}
